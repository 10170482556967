/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TopContent from '../../../Shared/Components/TopContent';
import SelectBox from '../../../Shared/Components/Form/SelectBox';
import { Input, InputInnerSymbol } from '../../../Shared/Components/Form/Input';
import Button from '../../../Shared/Components/Button';

import { addPlanDetails } from '../../../redux/slices/PlanSlice';

import plansApi from '../../../utils/api/plans';
import Success from '../../../Shared/Components/Success';
import ModalBox from '../../../Shared/Components/ModalBox';
import { currencySymbol } from '../../../utils/helpers';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function PlansAdd() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [buttonStatus, setButtonStatus] = useState('');

  const [value, setValue] = useState({});
  const [features, setFeatures] = useState(['', '', '', '']);
  const [pricePerDevice, setPricePerDevice] = useState([
    {
      currency: 'SAR',
      price_in_months: [
        {
          month: 1,
          price: null,
        },
        {
          month: 6,
          price: null,
        },
        {
          month: 12,
          price: null,
        },
      ],
    },
    {
      currency: 'USD',
      price_in_months: [
        {
          month: 1,
          price: null,
        },
        {
          month: 6,
          price: null,
        },
        {
          month: 12,
          price: null,
        },
      ],
    },
    {
      currency: 'EUR',
      price_in_months: [
        {
          month: 1,
          price: null,
        },
        {
          month: 6,
          price: null,
        },
        {
          month: 12,
          price: null,
        },
      ],
    },
    {
      currency: 'INR',
      price_in_months: [
        {
          month: 1,
          price: null,
        },
        {
          month: 6,
          price: null,
        },
        {
          month: 12,
          price: null,
        },
      ],
    },
  ]);

  const [disable, setDisable] = useState(true);

  const products = [
    {
      label: 'CMS',
      key: 'cms',
    },
    {
      label: 'Advertiser',
      key: 'advertiser',
    },
  ];

  const planTypes = [
    {
      label: t('subscription'),
      key: 'subscription',
    },
    // {
    //   label: 'Perpetual',
    //   key: 'perpetual',
    // },
  ];

  const clickFunction = () => {
    navigate('../');
  };

  const handleChange = (name, data) => {
    if (data) {
      if (name === 'product') {
        setValue({ ...value, [name]: data.label, productKey: data.key });
      } else if (name === 'plan_type') {
        setValue({ ...value, [name]: data.label, planKey: data.key });
      }
    }
    if (name !== 'product' && name !== 'plan_type') {
      setValue({ ...value, [name]: data });
    }
  };

  const handleFeatureChange = (index, data) => {
    const updatedFeatures = [...features];
    // Update the value at the specified index
    updatedFeatures[index] = data;
    setFeatures(updatedFeatures);
  };

  const handleChangeDevice = (currency, changeValue, index) => {
    const updatedPricePerDevice = pricePerDevice.map((device) => {
      if (device.currency === currency) {
        const updatedPriceInMonths = device.price_in_months.map((month) => {
          if (month.month === index) {
            return { ...month, price: changeValue ? Number(changeValue) : null };
          }
          return month;
        });
        return { ...device, price_in_months: updatedPriceInMonths };
      }
      return device;
    });
    setPricePerDevice(updatedPricePerDevice);
  };

  const addRemoveFeature = (action, indexToRemove) => {
    if (action === 'add') {
      setFeatures((prev) => [
        ...prev,
        '',
      ]);
    } else if (action === 'remove') {
      const updatedFeatures = [...features.slice(0, indexToRemove), ...features.slice(indexToRemove + 1)];
      setFeatures(updatedFeatures);
    }
  };

  const submit = async () => {
    setDisable(true);
    setButtonStatus('loading');
    const planData = {
      name: value?.plan_name,
      product: value?.productKey,
      plan_type: value?.planKey,
      description: value?.description,
      price_per_device: pricePerDevice,
      // price_per_storage_in_months: pricePerStorage,
      features: features.filter((feature) => feature !== ''),
    };

    await plansApi.addPlan(planData)
      .then((response) => {
        setDisable(false);
        setButtonStatus('success');
        setTimeout(() => {
          setButtonStatus('');
          dispatch(addPlanDetails(response?.data));
          navigate('../');
        }, 3000);
      })
      .catch((err) => {
        setDisable(false);
        setButtonStatus('');
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  useEffect(() => {
    if (value?.product
      && value?.plan_type
      && value?.plan_name
      && features[0] !== ''
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value, features]);

  return (
    <div className="main-container">
      <ModalBox
        status={buttonStatus === 'success'}
        setOpen={setButtonStatus}
        modalView={{ content: <Success message={t('planCreatedSuccess')} /> }}
        notification
      />
      <TopContent
        label={[t('plansAndPricing'), t('newPlan')]}
        buttonClass="success-button"
        click={clickFunction}
      />
      <div className="plans-form-wrap">
        <div className="plans-details">
          <div className="single-row">
            <SelectBox
              label={t('product')}
              name="product"
              required
              value={value.product}
              onchange={handleChange}
              lists={products}
              id="select-plans"
              placeholder={t('selectProduct')}
            />
            <SelectBox
              label={t('planType')}
              name="plan_type"
              required
              value={value.plan_type}
              onchange={handleChange}
              lists={planTypes}
              id="select-plans"
              placeholder={t('enterPlanType')}
            />
          </div>
          <div className="single-row">
            <Input
              label={t('description')}
              type="text"
              name="description"
              required
              value={value.description}
              change={handleChange}
              placeholder={t('enterDescription')}
            />
            <Input
              label={t('planName')}
              type="text"
              name="plan_name"
              required
              value={value.plan_name}
              change={handleChange}
              placeholder={t('enterPlanName')}
            />
          </div>
        </div>
        <div className="features-pricing">
          <div className="features">
            <h1>{t('features')}</h1>
            {features.map((feature, index) => (
              <div className="each-feature">
                <Input
                  label=""
                  type="text"
                  name={index}
                  value={feature}
                  change={handleFeatureChange}
                  placeholder={t('enterPlanFeature')}
                />
                {index === 3
                  && (
                    <div
                      role="presentation"
                      onClick={() => addRemoveFeature('add')}
                      className="add-feature"
                    >
                      {t('add')}
                    </div>
                  )}
                {index > 3
                  && (
                    <div
                      role="presentation"
                      onClick={() => addRemoveFeature('remove', index)}
                      className="remove-feature"
                    >
                      {t('remove')}
                    </div>
                  )}
              </div>
            ))}
          </div>
          <div className="pricing">
            <table className="device">
              <tr>
                <th>{t('deviceDuration')}</th>
                {
                  pricePerDevice?.[0]?.price_in_months?.map((val) => (
                    <td
                      className={val?.month === 12 && 'last-data'}
                    >
                      {val.month} {val.month > 1 ? t('months') : t('month')}
                    </td>
                  ))
                }
              </tr>
              {
                pricePerDevice.map((item, index) => (
                  <tr>
                    <th
                      className={index === pricePerDevice.length - 1 && 'bottom-data'}
                    >
                      <span>{currencySymbol(item.currency)}&nbsp;</span>
                      {item.currency}
                    </th>
                    {item?.price_in_months?.map((month) => (
                      <td
                        label="table-data"
                        className={
                          month?.month === 12
                            ? 'last-data'
                            : index === pricePerDevice.length - 1
                              ? 'bottom-data'
                              : index === pricePerDevice.length - 1 && month?.month === 12
                                ? 'last-data bottom-data'
                                : ''
                        }
                      >
                        <div className="input-container">
                          <InputInnerSymbol
                            type="number"
                            name={item?.currency}
                            index={month.month}
                            value={month.price}
                            change={handleChangeDevice}
                            placeholder={0}
                            symbol={currencySymbol(item.currency)}
                          />
                        </div>
                      </td>
                    ))}
                  </tr>
                ))
              }
            </table>
          </div>
        </div>
        <div className="form-button-group">
          <div className="form-button">
            <Button label={t('back')} click={clickFunction} classes="default-button" />
          </div>
          <div className="form-button">
            <Button
              label={buttonStatus === 'loading' ? t('creating') : t('create')}
              click={submit}
              classes="success-button"
              disabled={disable}
              loading={buttonStatus === 'loading'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlansAdd;
